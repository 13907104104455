<template>
  <div class="wi100 hi100">
    <iframe class="frame-wrap" src="http://www.zykjfw.cn/index"></iframe>
  </div>
</template>

<script>
  export default {
    name: 'zykjfw'
  }
</script>

<style scoped>
  .frame-wrap {
    width: 100%;
    height: calc(100vh - 5px);
  }
</style>
